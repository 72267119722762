<template>
  <div class="register">
    <uregister/>
  </div>
</template>

<script>
// @ is an alias to /src
import uregister from "@/components/uregister";

export default {
  name: "register",
  components: {
    uregister
  }
};
</script>