<template>
  <div class="login-page">
    <div class="logo" @click="toindex()">
      <img src="@/assets/logo.png" />
    </div>
    <div class="bg"></div>
    <div class="ikon">
      <img class="img-ik" src="@/assets/ikon.png">
      <div class="login-form">
        <p>注册</p>
        <div>
          <label class="lab">手机号:</label>
          <input class="input_text" maxlength="11" type="text" v-model="phone" @blur="checkMoble(phone)" />
        </div>
        <div class="hintr" v-if="istrue==1">手机号可以使用</div>
         <div class="hintf" v-else-if="istrue==2">手机号格式错误</div>
        <div class="hintf" v-else></div>
        <div class="submit">
          <span @click="toreg(istrue,phone)"  size="large" class="regbtn">立即申请</span>
        </div>
        <div class="tologin">
          已有账号？
          <router-link tag="span" to="./Login">
            <span>去登陆</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "register",
  // inject:['app'],
  data() {
    return {
      phone: "",
      istrue: 0,
    };
  },
  mounted() {
    this.$parent.$parent.$parent.sskj = false;
  },
  methods: {
    toindex(){
      this.$router.push("./homepage")
    },
    toreg(str,id) {
      if(str==1){
        this.$router.push({
          path: '/verify',
          query: {
            id: id
          }
        })
      }else{
        this.$message({
          message: "请正确填写手机号",
          type: 'warning',
          center:'true',
          offset: '60',
        });
      }
    },
    checkMoble(num){
      var re = /^1\d{10}$/;
  if (re.test(num)) {
    this.istrue=1;
  } else {
    this.istrue=2;
  }
    }
  }
};
</script>

<style lang="less" scoped>
.login-page {
  top: 80px;
  position: fixed;
  padding: 0px;
  margin: 0;
  font-size: 0px;
  height: 80%;
  width: 100%;
  box-sizing: border-box;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../../assets/Login_bg.png) no-repeat;
    opacity: 0.9;
    z-index: -10;
  }
}
.logo {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 360px;
  top: -70px;
  > img {
    width: 100%;
    height: 100%;
  }
}

.ikon {
  position: absolute;
  z-index: 5;
  padding: 0;
  align-items: center;
  width: 1000px;
  height: 500px;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
  overflow: hidden;
  background: rgba(118, 100, 229, 1);
  box-shadow: -2px 0px 24px 0px rgba(61, 49, 226, 0.24);
  > img {
    width: 570px;
    height: 100%;
  }
  .login-form {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    height: 500px;
    width: 430px;
    background-color: #fff;
    padding: 70px;
    box-sizing: border-box;
    > div {
      border-radius: 20px;
      overflow: hidden;
    }
    > p {
      width: 48px;
      height: 24px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 34px;
    }
    .lab {
      width: 51px;
      height: 16px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 33px;
    }
    .input_text {
      width: 240px;
      height: 40px;
      border: none;
      outline: none;
      text-indent: 10px;
      padding-left: 10px;
      font-size: 18px;
      background: rgba(238, 238, 238, 1);
      border-radius: 20px;
      z-index: 10;
    }
    .hintr{
      position: absolute;
      width: 120px;
      height: 30px;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgb(64, 241, 10);
      line-height: 33px;
      right: -14px;
      top:130px;
    }
    .hintf{
      position: absolute;
      width: 100px;
      height: 30px;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgb(240, 42, 15);
      line-height: 33px;
      right: 5px;
      top:130px;
    }
    .submit {
      height: 50px;
      margin: 10px;
      margin-top: 40px;
      cursor: pointer;
    }
    .submit .regbtn {
      width: 240px;
      height: 40px;
      background: rgba(55, 67, 214, 1);
      border-radius: 20px;
      display: block;
      color: #fff;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 33px;
      text-align: center;
    }
    .tologin {
      width: 120px;
      height: 30px;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgba(0, 0, 0, 1);
      line-height: 33px;
      margin-left: 70px;
      > span {
        color: #2860ea;
        cursor: pointer;
      }
    }
  }
}
</style>